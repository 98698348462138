<template>
  <div style="height: 100vh;" class="login_bg">
    <v-row justify="center">
      <v-col
        :xl="3"
        :lg="4"
        :md="6"
        :sm="8"
        :cols="10"
      >
        <v-card
          :loading="loading"
          class="mx-auto px-10 mt-6 pb-10"
          style="background-color: rgb(255 255 255 / 90%);"
          outlined
        >
          <img
            class="logo mb-10 mt-10"
            src="../assets/logo-havebike-new2019.png"
          >

          <h2 class="text-center">{{ setupMessage }}</h2>
          <v-btn
            class="mx-auto d-block mt-2"
            small
            text
            depressed
            @click="$auth.logout()"
          >
            Cancel
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import AtlasWebsocket from '@/websockets.js'

export default {
  name: 'AuthCallbackPage',

  data () {
    return {
      loading: true,
      setupMessage: 'Setting Up Account...'
    }
  },

  mounted () {
    this.$auth.getToken(this.$route.query)
      .then(() => {
        // Need to wait at least 1s to allow the JWT to be valid.
        setTimeout(() => {
          this.setupMessage = 'Retrieving User Information...'
          this.$auth.getUser()
            .then(() => {
              setTimeout(() => {
                AtlasWebsocket.init()
                window.localStorage.setItem('dashboard', 'DefaultDashboard')
                this.$router.push({ path: '/', replace: true })
              }, 1000)
            })
            .catch(() => {
              setTimeout(() => {
                this.loading = false
                this.setupMessage = 'There was an error retrieving user information. Please cancel and try again.'
              }, 1000)
            })
          }, 1500)
      })
      .catch(() => { this.$router.replace('login') })
  }
}
</script>
<style type="text/css">
  body {
    padding: 0;
  }
  .login_bg {
    background-image: url('https://havebike-cdn.s3.eu-west-2.amazonaws.com/assets/home_banner.jpg');
    background-size: cover;
  }
  .logo {
    width: 100%;
    margin: auto;
    display: block;
    max-width: 160px;
  }
</style>